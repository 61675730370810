<template>
  <div class="card-body">
    <h5>
      {{ (room)? room.roomClass.text :''}} {{ $t("product-page.room") }} <span>{{ index + 1 }}</span>
    </h5>

    <div class="d-flex justify-content-between">
      <div class="p-2" :style="lang === 'he'? 'direction:rtl;':'direction:ltr;'">
        <span v-if="room.adult === 1">{{ room.adult }} {{ $t("product-page.adult") }}</span>
        <span v-else>{{ room.adult }} {{ $t("product-page.adults") }}</span>
        <span v-if="room.child && room.child === 1">, {{ room.child }} {{ $t("product-page.child") }}</span>
        <span v-if="room.child && room.child > 1">, {{ room.child }} {{ $t("product-page.children") }}</span>

        <!-- <p v-if="!room.infant">{{ $t("product-page.no-babies") }}</p> -->
        <p v-if="room.infant && room.infant === 1">{{ room.infant }} {{ $t("product-page.infant") }}</p>
        <p v-if="room.infant && room.infant > 1">{{ room.infant }} {{ $t("product-page.infants") }}</p>
      </div>
      <div class="p-2">
        <p class="m-0"><img :src="`${imageDomain}/assets/img/icon_x.png`" alt="icon-x" width="15" height="15"/></p>
        <p v-if="room.infant"><img :src="`${imageDomain}/assets/img/icon_x.png`" alt="icon-x" width="15" height="15"/></p>
      </div>

      <div class="p-2" v-if="is1MAMISHAgency">
        <p class="m-0">{{ getPriceWithSymbol( room.cc, 0) }}</p>
      </div>
      <div class="p-2" v-else>
        <p class="m-0">{{ getPriceWithSymbol( room.cc, room.price) }}</p>
        <p v-if="room.infant">{{ getPriceWithSymbol( room.cc, room.priceInf) }}</p>
      </div>

      <div class="p-2">
        <p class="m-0"><img :src="`${imageDomain}/assets/img/Equal.png`" alt="icon-equal" width="20" height="20"/></p>
        <p class="m-0" v-if="room.infant"><img :src="`${imageDomain}/assets/img/Equal.png`" alt="icon-equal" width="20" height="20"/></p>
        <p v-if="room.infant && Number(room.oldTotal) > Number(room.totalPrice)">
          <s v-if="!isOdysseySite && !bypassPaymentState">{{ getPriceWithSymbol( room.cc, room.oldTotal) }}</s>
        </p>
      </div>

      <div class="p-2" v-if="is1MAMISHAgency">
        <p class="m-0">{{ getPriceWithSymbol( room.cc, 0) }}</p>
      </div>
      <div class="p-2" v-else>
        <p class="m-0" v-if="room.infant">{{ getPriceWithSymbol( room.cc, room.totalPrice - room.priceInf * room.infant) }}</p>
        <p class="m-0" v-if="room.infant">{{ getPriceWithSymbol( room.cc, room.priceInf * room.infant) }}</p>
        <p class="m-0"> {{ getPriceWithSymbol( room.cc, room.totalPrice) }}</p>
        <p v-if="!room.infant && Number(room.oldTotal) > Number(room.totalPrice)">
          <s v-if="!isOdysseySite && !bypassPaymentState">{{ getPriceWithSymbol( room.cc, room.oldTotal) }}</s>
        </p>
      </div>
    </div>

    <button class="js-del-row" v-if="index > 0" @click="remove">
      <i class="far fa-window-close"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    room: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      is1MAMISHAgency: 'GET_IS_1MAMISH_AGENCY',
    }),
  },
  methods: {
    remove() {
      this.$emit('closed', this.index);
    },
  },
};
</script>
<style scoped>
s {
  opacity: 0.5;
}
</style>
